header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: $background_blue;
  overflow: hidden;

  nav {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: content-box;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }

    h1 {
      color: $white;
      text-align: center;
      margin: 0px 0px 50px;
      padding: 0 20px;
      font-weight: 200;
      font-size: 22px;
      display: block;
      float: left;
      width: 100%;
    }

    .desktopmenu {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .menu {
      flex: 1 0 auto;
    }

    .dropdown {
      cursor: pointer;
    }

    .mobilemenu {
      display: none;
      width: 100%;
      position: relative;
      height: 60px;

      .openmenu {
        color: white;
        font-size: 50px;
        line-height: 60px;
        width: 60px;
        background: rgba($white, 0.1);
      }

      .logo {
        position: absolute;
        height: 60px;
        margin: 0 auto;
        width: 150px;
        left: 0;
        right: 0;
        top: 7px;

        embed,
        img {
          pointer-events: none;
        }
      }
    }

    .navbottom {
      flex-shrink: 0;
      z-index: 1;
      margin: 40px 0 10px;

      a {
        color: rgba($white, 0.7);
        margin: 5px 20px;
        width: 100%;
        display: block;
        text-decoration: none;
        @include transition(color, 0.25s, ease-in);

        &:hover {
          color: rgba($white, 1);
        }
      }
    }

    .nav-address {
      flex-shrink: 0;
      z-index: 4;
      margin: 20px 0 10px;

      a {
        color: rgba($white, 0.6);
        margin: 5px 20px;
        width: 100%;
        display: block;
        text-decoration: none;
        @include transition(color, 0.25s, ease-in);
        font-size: 20px;
        &:hover {
          color: rgba($white, 1);
        }
      }
    }
  }
}
.nav-address {
  padding: 10px 20px;
  position: relative;
  display: block;
  text-decoration: none;
  font-size: 14px;
  color: $white;
  border-top: 1px solid $menu_btn_border;
  // transition: background .25s ease-in;
  @include transition(background, 0.25s, ease-in);
  font-size: 18px;
  bottom: 15px;
}
.logo {
  margin: 5px 10px;
  float: left;

  embed,
  img {
    width: 100%;
    pointer-events: none;
  }
}

ul {
  list-style: none;
  float: left;
  overflow: hidden;
  // transition: max-height 1.25s ease-in;

  li {
    width: 100%;
    float: left;

    a {
      padding: 10px 20px;
      position: relative;
      display: block;
      text-decoration: none;
      font-size: 14px;
      color: $white;
      border-top: 1px solid $menu_btn_border;
      // transition: background .25s ease-in;
      @include transition(background, 0.25s, ease-in);
      font-size: 18px;

      i {
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 15px;
        font-size: 12px;
        @include transition(opacity, 0.25s, ease-in);
        // transition: opacity .25s ease-in;
      }

      &:hover {
        background: $menu_btn_hover;
      }
    }

    &.open > a,
    &.open > ul > li > a,
    &.active > a,
    &.active > ul > li > a {
      background: $menu_btn_active !important;
    }

    &.open > a > i {
      opacity: 0.7;
    }

    &.open a:hover,
    a.active {
      box-shadow: inset 17px 0px 40px -20px rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 17px 0px 40px -20px rgba(255, 255, 255, 0.3);
      -moz-box-shadow: inset 17px 0px 40px -20px rgba(255, 255, 255, 0.3);
    }

    ul {
      max-height: 0;

      li {
        a {
          padding-left: 30px;
          font-weight: 300;
          font-size: 16px;
        }

        ul li a {
          padding-left: 40px;
          font-weight: 200;
          font-size: 15px;
        }
      }
    }

    &.open > ul {
      max-height: 999px;
      width: 100%;
    }
  }
}

nav > ul {
  border-bottom: 1px solid $menu_btn_border;
}

@media (max-width: 900px) {
  header {
    position: relative;
    width: 100% !important;

    .desktopmenu {
      display: none !important;

      .logo {
        display: none;
      }

      &.open {
        display: flex !important;
        background: $background_blue;
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 40px;
        z-index: 1;
      }
    }

    .mobilemenu {
      display: block !important;
    }
  }
}
.loaderTokens {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 25px;
  img {
    width: 17px;
  }
}

.bubble {
  display: block;
  position: absolute;
  cursor: pointer;
  margin: 9px 0;
}

.bubble:hover:after {
  background-color: rgb(30, 179, 30);
}

.bubble:after {
  content: "";
  background-color: #90b629;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 1px;
  left: 1px;
}

.bubble .bubble-outer-dot {
  margin: 1px;
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(67, 221, 67, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

.bubble .bubble-inner-dot {
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(179, 255, 0, 0.4);

  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

.bubble .bubble-inner-dot:after {
  content: "";
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(130, 199, 18, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

@-webkit-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@-moz-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@-o-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.bubble-off {
  display: block;
  position: absolute;
  cursor: pointer;
  margin: 9px 0;
}

.bubble-off:hover:after {
  background-color: rgb(255, 0, 0);
}

.bubble-off:after {
  content: "";
  background-color: rgb(245, 35, 35);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 1px;
  left: 1px;
}

.bubble-off.bubble-outer-dot-off {
  margin: 1px;
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

.bubble-off .bubble-inner-dot-off {
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.4);

  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

.bubble-off .bubble-inner-dot-off:after {
  content: "";
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(255, 0, 0, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}
.nav-address {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.account-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  h1 {
    margin: 0;
  }

  .account-address {
    font-size: 14px;
    margin: 10px 0;
    color: #ccc;
    transition: color .3s linear;
    
    &:hover {
      color: rgba($white, 1);
    }
  }
}
