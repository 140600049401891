@mixin box-shadow($left, $top, $radius, $color) {
  box-shadow: $left $top $radius $color;
  -webkit-box-shadow: $left $top $radius $color;
  -moz-box-shadow: $left $top $radius $color;
}

@mixin transition($property, $duration, $easing: linear) {
  transition: $property $duration $easing;
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
  border-top-left-radius: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -moz-border-radius-topleft: $topleft;
  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
}

@mixin gradient($color1, $color2) {
  background-color: $color1;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
  background-image: -moz-linear-gradient(center top, $color1, $color2);
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from($color1),
    to($color2)
  );
}

@mixin filter($filter-type, $filter-amount) {
  -webkit-filter: $filter-type + unquote("(#{$filter-amount})");
  -moz-filter: $filter-type + unquote("(#{$filter-amount})");
  -ms-filter: $filter-type + unquote("(#{$filter-amount})");
  -o-filter: $filter-type + unquote("(#{$filter-amount})");
  filter: $filter-type + unquote("(#{$filter-amount})");
}
